.tagline > label:before {
  content: "​";
  content: "​" / " weird";
  alt: " weird";
  white-space: break-spaces;
  will-change: content;
  animation: 33.54s linear 1s forwards typed-0, .6s linear 34.54s forwards typed-0-final;
}

@keyframes typed-0-final {
  0%, 16.6667% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  16.6677%, 33.3333% {
    content: "​ w";
    content: "​ w" / " weird";
    alt: " weird";
  }

  33.3343%, 50% {
    content: "​ we";
    content: "​ we" / " weird";
    alt: " weird";
  }

  50.001%, 66.6667% {
    content: "​ wei";
    content: "​ wei" / " weird";
    alt: " weird";
  }

  66.6677%, 83.3333% {
    content: "​ weir";
    content: "​ weir" / " weird";
    alt: " weird";
  }

  83.3343%, 100% {
    content: "​ weird";
    content: "​ weird" / " weird";
    alt: " weird";
  }
}

.tagline > label:after {
  content: "​";
  white-space: nowrap;
  border-right: 2px solid;
  padding-right: 0;
  animation: .75s linear 1s infinite forwards typed-0-caret;
  display: inline-block;
  position: relative;
}

@keyframes typed-0 {
  0%, .297151% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  .298151%, .595303% {
    content: "​ t";
    content: "​ t" / " weird";
    alt: " weird";
  }

  .596303%, .893454% {
    content: "​ ti";
    content: "​ ti" / " weird";
    alt: " weird";
  }

  .894454%, 1.19161% {
    content: "​ tin";
    content: "​ tin" / " weird";
    alt: " weird";
  }

  1.19261%, 7.69131% {
    content: "​ tiny";
    content: "​ tiny" / " weird";
    alt: " weird";
  }

  7.69231%, 7.92983% {
    content: "​ tin";
    content: "​ tin" / " weird";
    alt: " weird";
  }

  7.93083%, 8.16835% {
    content: "​ ti";
    content: "​ ti" / " weird";
    alt: " weird";
  }

  8.16935%, 8.40687% {
    content: "​ t";
    content: "​ t" / " weird";
    alt: " weird";
  }

  8.40787%, 8.64539% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  8.64639%, 11.6269% {
    content: "​";
    content: "​" / " weird";
    alt: " weird";
  }

  11.6279%, 11.9251% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  11.9261%, 12.2232% {
    content: "​ l";
    content: "​ l" / " weird";
    alt: " weird";
  }

  12.2242%, 12.5214% {
    content: "​ lo";
    content: "​ lo" / " weird";
    alt: " weird";
  }

  12.5224%, 12.8195% {
    content: "​ lov";
    content: "​ lov" / " weird";
    alt: " weird";
  }

  12.8205%, 13.1177% {
    content: "​ love";
    content: "​ love" / " weird";
    alt: " weird";
  }

  13.1187%, 13.4158% {
    content: "​ lovel";
    content: "​ lovel" / " weird";
    alt: " weird";
  }

  13.4168%, 19.9155% {
    content: "​ lovely";
    content: "​ lovely" / " weird";
    alt: " weird";
  }

  19.9165%, 20.154% {
    content: "​ lovel";
    content: "​ lovel" / " weird";
    alt: " weird";
  }

  20.155%, 20.3926% {
    content: "​ love";
    content: "​ love" / " weird";
    alt: " weird";
  }

  20.3936%, 20.6311% {
    content: "​ lov";
    content: "​ lov" / " weird";
    alt: " weird";
  }

  20.6321%, 20.8696% {
    content: "​ lo";
    content: "​ lo" / " weird";
    alt: " weird";
  }

  20.8706%, 21.1081% {
    content: "​ l";
    content: "​ l" / " weird";
    alt: " weird";
  }

  21.1091%, 21.3466% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  21.3476%, 24.3282% {
    content: "​";
    content: "​" / " weird";
    alt: " weird";
  }

  24.3292%, 24.6263% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  24.6273%, 24.9245% {
    content: "​ t";
    content: "​ t" / " weird";
    alt: " weird";
  }

  24.9255%, 25.2226% {
    content: "​ te";
    content: "​ te" / " weird";
    alt: " weird";
  }

  25.2236%, 25.5208% {
    content: "​ tec";
    content: "​ tec" / " weird";
    alt: " weird";
  }

  25.5218%, 25.8189% {
    content: "​ tech";
    content: "​ tech" / " weird";
    alt: " weird";
  }

  25.8199%, 26.1171% {
    content: "​ techn";
    content: "​ techn" / " weird";
    alt: " weird";
  }

  26.1181%, 26.4152% {
    content: "​ techni";
    content: "​ techni" / " weird";
    alt: " weird";
  }

  26.4162%, 26.7134% {
    content: "​ technic";
    content: "​ technic" / " weird";
    alt: " weird";
  }

  26.7144%, 27.0115% {
    content: "​ technica";
    content: "​ technica" / " weird";
    alt: " weird";
  }

  27.0125%, 33.5112% {
    content: "​ technical";
    content: "​ technical" / " weird";
    alt: " weird";
  }

  33.5122%, 33.7497% {
    content: "​ technica";
    content: "​ technica" / " weird";
    alt: " weird";
  }

  33.7507%, 33.9883% {
    content: "​ technic";
    content: "​ technic" / " weird";
    alt: " weird";
  }

  33.9893%, 34.2268% {
    content: "​ techni";
    content: "​ techni" / " weird";
    alt: " weird";
  }

  34.2278%, 34.4653% {
    content: "​ techn";
    content: "​ techn" / " weird";
    alt: " weird";
  }

  34.4663%, 34.7038% {
    content: "​ tech";
    content: "​ tech" / " weird";
    alt: " weird";
  }

  34.7048%, 34.9424% {
    content: "​ tec";
    content: "​ tec" / " weird";
    alt: " weird";
  }

  34.9434%, 35.1809% {
    content: "​ te";
    content: "​ te" / " weird";
    alt: " weird";
  }

  35.1819%, 35.4194% {
    content: "​ t";
    content: "​ t" / " weird";
    alt: " weird";
  }

  35.4204%, 35.6579% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  35.6589%, 38.6394% {
    content: "​";
    content: "​" / " weird";
    alt: " weird";
  }

  38.6404%, 38.9376% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  38.9386%, 39.2357% {
    content: "​ h";
    content: "​ h" / " weird";
    alt: " weird";
  }

  39.2367%, 39.5339% {
    content: "​ ha";
    content: "​ ha" / " weird";
    alt: " weird";
  }

  39.5349%, 39.832% {
    content: "​ han";
    content: "​ han" / " weird";
    alt: " weird";
  }

  39.833%, 40.1302% {
    content: "​ hand";
    content: "​ hand" / " weird";
    alt: " weird";
  }

  40.1312%, 46.6299% {
    content: "​ handy";
    content: "​ handy" / " weird";
    alt: " weird";
  }

  46.6309%, 46.8684% {
    content: "​ hand";
    content: "​ hand" / " weird";
    alt: " weird";
  }

  46.8694%, 47.1069% {
    content: "​ han";
    content: "​ han" / " weird";
    alt: " weird";
  }

  47.1079%, 47.3455% {
    content: "​ ha";
    content: "​ ha" / " weird";
    alt: " weird";
  }

  47.3465%, 47.584% {
    content: "​ h";
    content: "​ h" / " weird";
    alt: " weird";
  }

  47.585%, 47.8225% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  47.8235%, 50.804% {
    content: "​";
    content: "​" / " weird";
    alt: " weird";
  }

  50.805%, 51.1022% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  51.1032%, 51.4003% {
    content: "​ u";
    content: "​ u" / " weird";
    alt: " weird";
  }

  51.4013%, 51.6985% {
    content: "​ uw";
    content: "​ uw" / " weird";
    alt: " weird";
  }

  51.6995%, 58.1982% {
    content: "​ uwu";
    content: "​ uwu" / " weird";
    alt: " weird";
  }

  58.1992%, 58.4367% {
    content: "​ uw";
    content: "​ uw" / " weird";
    alt: " weird";
  }

  58.4377%, 58.6752% {
    content: "​ u";
    content: "​ u" / " weird";
    alt: " weird";
  }

  58.6762%, 58.9137% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  58.9147%, 61.8952% {
    content: "​";
    content: "​" / " weird";
    alt: " weird";
  }

  61.8962%, 62.1934% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  62.1944%, 62.4915% {
    content: "​ s";
    content: "​ s" / " weird";
    alt: " weird";
  }

  62.4925%, 62.7897% {
    content: "​ se";
    content: "​ se" / " weird";
    alt: " weird";
  }

  62.7907%, 63.0879% {
    content: "​ sen";
    content: "​ sen" / " weird";
    alt: " weird";
  }

  63.0888%, 63.386% {
    content: "​ sens";
    content: "​ sens" / " weird";
    alt: " weird";
  }

  63.387%, 63.6842% {
    content: "​ sensi";
    content: "​ sensi" / " weird";
    alt: " weird";
  }

  63.6852%, 63.9823% {
    content: "​ sensib";
    content: "​ sensib" / " weird";
    alt: " weird";
  }

  63.9833%, 64.2805% {
    content: "​ sensibl";
    content: "​ sensibl" / " weird";
    alt: " weird";
  }

  64.2815%, 70.7802% {
    content: "​ sensible";
    content: "​ sensible" / " weird";
    alt: " weird";
  }

  70.7812%, 71.0187% {
    content: "​ sensibl";
    content: "​ sensibl" / " weird";
    alt: " weird";
  }

  71.0197%, 71.2572% {
    content: "​ sensib";
    content: "​ sensib" / " weird";
    alt: " weird";
  }

  71.2582%, 71.4957% {
    content: "​ sensi";
    content: "​ sensi" / " weird";
    alt: " weird";
  }

  71.4967%, 71.7342% {
    content: "​ sens";
    content: "​ sens" / " weird";
    alt: " weird";
  }

  71.7352%, 71.9728% {
    content: "​ sen";
    content: "​ sen" / " weird";
    alt: " weird";
  }

  71.9738%, 72.2113% {
    content: "​ se";
    content: "​ se" / " weird";
    alt: " weird";
  }

  72.2123%, 72.4498% {
    content: "​ s";
    content: "​ s" / " weird";
    alt: " weird";
  }

  72.4508%, 72.6883% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  72.6893%, 75.6698% {
    content: "​";
    content: "​" / " weird";
    alt: " weird";
  }

  75.6708%, 75.968% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  75.969%, 76.2661% {
    content: "​ o";
    content: "​ o" / " weird";
    alt: " weird";
  }

  76.2671%, 76.5643% {
    content: "​ op";
    content: "​ op" / " weird";
    alt: " weird";
  }

  76.5653%, 76.8625% {
    content: "​ ope";
    content: "​ ope" / " weird";
    alt: " weird";
  }

  76.8634%, 83.3622% {
    content: "​ open";
    content: "​ open" / " weird";
    alt: " weird";
  }

  83.3631%, 83.6007% {
    content: "​ ope";
    content: "​ ope" / " weird";
    alt: " weird";
  }

  83.6017%, 83.8392% {
    content: "​ op";
    content: "​ op" / " weird";
    alt: " weird";
  }

  83.8402%, 84.0777% {
    content: "​ o";
    content: "​ o" / " weird";
    alt: " weird";
  }

  84.0787%, 84.3162% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  84.3172%, 87.2978% {
    content: "​";
    content: "​" / " weird";
    alt: " weird";
  }

  87.2987%, 87.5959% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  87.5969%, 87.8941% {
    content: "​ c";
    content: "​ c" / " weird";
    alt: " weird";
  }

  87.8951%, 88.1922% {
    content: "​ cu";
    content: "​ cu" / " weird";
    alt: " weird";
  }

  88.1932%, 88.4904% {
    content: "​ cur";
    content: "​ cur" / " weird";
    alt: " weird";
  }

  88.4914%, 88.7885% {
    content: "​ curs";
    content: "​ curs" / " weird";
    alt: " weird";
  }

  88.7895%, 89.0867% {
    content: "​ curse";
    content: "​ curse" / " weird";
    alt: " weird";
  }

  89.0877%, 95.5864% {
    content: "​ cursed";
    content: "​ cursed" / " weird";
    alt: " weird";
  }

  95.5874%, 95.8249% {
    content: "​ curse";
    content: "​ curse" / " weird";
    alt: " weird";
  }

  95.8259%, 96.0634% {
    content: "​ curs";
    content: "​ curs" / " weird";
    alt: " weird";
  }

  96.0644%, 96.3019% {
    content: "​ cur";
    content: "​ cur" / " weird";
    alt: " weird";
  }

  96.3029%, 96.5404% {
    content: "​ cu";
    content: "​ cu" / " weird";
    alt: " weird";
  }

  96.5414%, 96.779% {
    content: "​ c";
    content: "​ c" / " weird";
    alt: " weird";
  }

  96.78%, 97.0175% {
    content: "​ ";
    content: "​ " / " weird";
    alt: " weird";
  }

  97.0185%, 100% {
    content: "​";
    content: "​" / " weird";
    alt: " weird";
  }
}

@keyframes typed-0-caret {
  75% {
    border-color: #0000;
  }
}

.contact > li {
  transition-property: clip-path;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

@media (width >= 640px) and (any-hover) {
  .contact > li > a {
    transition: color .15s linear .3s, background-color .15s linear .3s, text-decoration-color .15s linear .3s;
  }
}

.c {
  transition: transform .15s ease-out;
}

.c:hover, .c:focus {
  transform: rotate(360deg);
}
/*# sourceMappingURL=animation.c97546b2.css.map */
