@use "typed";
@use "npm:nord/src/nord";

.tagline > label {
  @include typed.typed(
    " tiny", " lovely", " technical", " handy", " uwu", " sensible", " open", " cursed",
    [null],
    (
      end-on: (" weird"),
      iterations: 1,
      caret-width: 2px,
      caret-space: 0,
    ),
  );
}

.contact {
  > li {
    @apply transition-clip-path ease-out transition-duration-300;

    > a {
      @media (width >= 640px) and (any-hover) {
        transition: color 150ms linear, background-color 150ms linear, text-decoration-color 150ms linear;
        transition-delay: 300ms;
      }
    }
  }
}

.c {
  transition: 150ms transform ease-out;

  &:hover,
  &:focus {
    transform: rotate(360deg);
  }
}
